import { Auth0State } from '@om1/platform-authentication'
import { connectRedux } from '@om1/platform-utils'
import { surveyActions } from '../../state/survey-actions'
import { SurveyState } from '../../state/survey-reducer'
import { SurveyPageComponent } from './SurveyPageComponent'

export function createSurveyComponent<TState extends { survey: SurveyState; auth0: Auth0State }>() {
    return connectRedux(
        SurveyPageComponent,
        (state: TState) => ({
            activeStepIndex: state.survey.activeStepIndex,
            permissions: state.auth0.permissions
        }),
        { ...surveyActions }
    )
}

export type SurveyPage = ReturnType<typeof createSurveyComponent>
